import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/functions";
import "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBGc8AqyIrXVMpqcLNnooayoLqbk4fSr-E",
  authDomain: "techrepair-glass.firebaseapp.com",
  projectId: "techrepair-glass",
  storageBucket: "techrepair-glass.appspot.com",
  messagingSenderId: "772141911048",
  appId: "1:772141911048:web:c34b5cda2e34825a7555bd",
};

firebase.initializeApp(firebaseConfig);

const auth = firebase.auth();
const db = firebase.firestore();
const functions = firebase.functions();

if (window.location.href.includes("http://localhost")) {
  auth.useEmulator("http://localhost:5002");
  functions.useEmulator("localhost", 5001);
  db.useEmulator("localhost", 5000);
}

export { auth, db, functions };

export const generateUserDocument = async (user, additionalData) => {
  if (!user) return;
  const userRef = db.doc(`techs/${user.uid}`);
  const snapshot = await userRef.get();
  if (!snapshot.exists) {
    const { email } = user;
    try {
      await userRef.set({
        email,
        ...additionalData,
      });
    } catch (error) {
      console.log(error);
    }
  }
};
